.loader {
	position: relative;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: left !important;
	flex-wrap: wrap;

	&::after {
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -1;
		background-repeat: no-repeat;
		background-position-y: 100%;
		background-position-x: center;
		background-size: cover;
		background-position: center;
		// background-color: #f4f4f4;
		background-image: url(../../resources/img/bg-bienvenida.svg) !important;
	}

	& img {
		width: 50%;
		animation: pulse 1.5s infinite;
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 820px) and (orientation: portrait) {
	.loader {
		& .outer {
			width: 20.5em;
			height: 20.5em;
			margin-left: -10.7em;
			margin-top: -11.7em;
		}

		& .middle {
			width: 16.5em;
			height: 16.5em;
			margin-left: -8.75em;
			margin-top: -9.75em;
		}

		& img {
			width: 45%;
		}
	}
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
	.loader {
		& .outer {
			width: 20.5em;
			height: 20.5em;
			margin-left: -10.7em;
			margin-top: -11.7em;
		}

		& .middle {
			width: 16.5em;
			height: 16.5em;
			margin-left: -8.75em;
			margin-top: -9.75em;
		}

		& img {
			width: 15%;
		}
	}
}
