@font-face {
	font-family: 'Montserrat Regular';
	src: url('../../resources/fonts/Montserrat-Regular.ttf');
}

@font-face {
	font-family: 'MuseoModerno ExtraBold';
	src: url('../../resources/fonts/MuseoModerno-ExtraBold.ttf');
}

@font-face {
	font-family: 'Montserrat Bold';
	src: url('../../resources/fonts/Montserrat-Bold.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');

body {
	margin: 0;
	font-family: 'Montserrat Regular';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f4f4f4 !important;
	font-size: 18px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* movimiento arriba abajo */
.up-to-down {
	-webkit-animation: mover 1s infinite alternate;
	animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-10px);
	}
}

@keyframes mover {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-20px);
	}
}

@keyframes play {
	100% {
		background-position: 0 -7110px;
	}
}

#root {
	overflow: hidden;
}

#root::after {
	content: '';
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: fixed;
	z-index: -1;
	background-repeat: no-repeat;
	background-position-y: 100%;
	background-position-x: center;
	background-size: cover;
	background-position: center;
	// background-color: #f4f4f4;
	// background-image: url(../../resources/images/bg.svg);
}

p,
h2,
h3 {
	font-family: 'Montserrat Regular';
}

h1 {
	font-family: 'Montserrat Bold';
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
	color: #e10612;
}

.full {
	width: 100%;
}

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.bold {
	font-weight: bold !important;
}

.cursor {
	cursor: pointer;
}

.flex {
	display: flex;
}

.contenedor-streaming {
	width: 100%;
	text-align: center;
	// margin-bottom: 60px;

	& .estilo-streaming {
		width: 100%;
		height: 450px;
	}
}

/* width scroll */
::-webkit-scrollbar {
	width: 0px;
}

.mb30 {
	margin-bottom: 30px;
}

.mt30 {
	margin-top: 30px;
}

.mt50 {
	margin-top: 50px;
}

.mt {
	margin-top: 100px;
}

.padding {
	padding: 15px;
}

.button {
	background-color: #e10613 !important;
	color: #fff !important;
	font-family: 'Montserrat Bold' !important;
	border-radius: 20px !important;
	border: none !important;
	padding: 15px !important;
	width: 220px !important;
	font-size: 22px !important;
	margin: auto !important;
	cursor: pointer !important;
	transition: all 0.3s ease-in-out !important;
	// box-shadow: 12px 8px 33px -3px rgba(189, 189, 189, 1) !important;
	// -webkit-box-shadow: 12px 8px 33px -3px rgba(189, 189, 189, 1) !important;
	// -moz-box-shadow: 12px 8px 33px -3px rgba(189, 189, 189, 1) !important;

	&:hover {
		transform: scale(0.9);
	}
}

@media (prefers-color-scheme: dark) {
	body {
		background: #fff !important;
	}
}
@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
	.button {
		font-size: 18px !important;
	}
	.logo-header {
		margin-bottom: 30px;
	}
}

@media only screen and (min-width: 1800px) {
	.button {
		font-size: 22px !important;
	}

	.logo-header {
		width: 90%;
	}
}
